export interface User {
  id: number;
  name: string;
  slug: string;
  profileImage: string;
}

export type Account = {
  email: string;
  name: string;
  slug: string;
  profileImage: string;
} & AccountRole;

type AccountRole = {
  admin?: boolean;
  contentManager?: boolean;
  support?: boolean;
  reviewer?: boolean;
  contentCreator?: boolean;
  creator?: boolean;
  revenueShare?: boolean;
  mockupManager?: boolean;
  aiManager?: boolean;
  challengeManager?: boolean;
  taxonomyEditor?: boolean;
};

export enum Role {
  ADMIN = 'ADMIN',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  SUPPORT = 'SUPPORT',
  REVIEWER = 'REVIEWER',
  CONTENT_CREATOR = 'CONTENT_CREATOR',
  CREATOR = 'CREATOR',
  REVENUE_SHARE = 'REVENUE_SHARE',
  MOCKUP_MANAGER = 'MOCKUP_MANAGER',
  AI_MANAGER = 'AI_MANAGER',
  CHALLENGE_MANAGER = 'CHALLENGE_MANAGER',
  TAXONOMY_EDITOR = 'TAXONOMY_EDITOR',
}

// todo: remove this ugly block when API starts sending the correct uppercased role names on the user profile
export const RoleDict = {
  [Role.ADMIN]: 'admin',
  [Role.CONTENT_MANAGER]: 'contentManager',
  [Role.SUPPORT]: 'support',
  [Role.REVIEWER]: 'reviewer',
  [Role.CONTENT_CREATOR]: 'contentCreator',
  [Role.MOCKUP_MANAGER]: 'mockupManager',
  [Role.AI_MANAGER]: 'aiManager',
  [Role.CHALLENGE_MANAGER]: 'challengeManager',
  [Role.TAXONOMY_EDITOR]: 'taxonomyEditor',
};

export type UserWithToken = {
  token: string;
  id: string;
  email: string;
  googleId: string | null;
  onboardingState: string | null;
  passwordDigest: string | null;
  passwordUpdatedAt: Date;
  confirmed: boolean;
  facebookId: string | null;
  slug: string;
  name: string | null;
  profileImage: string | null;
  isTeam: boolean;
  description: string | null;
  location: string | null;
  website: string | null;
  instagram: string | null;
  followerCount: number;
  followingCount: number;
  challengeStats: string | null;
  noindex: boolean;
  createdAt: string | Date;
  featureGroups: string[];
  intercomHMAC: string;
  marketingOptIn: boolean;
  allowCommentEmails: boolean;
  allowShareViewEmails: boolean;
  plan: string | null;
  utilizedUploadSpace: number;
  uploadSpaceLimitReached: boolean;
  designsLimit: number;
  designsCount: number;
  designsLimitReached: boolean;
  shippingAddress: {
    name: string;
    line1: string;
    line2: string | null;
    postalCode: string;
    city: string;
    state: string;
    country: string;
  };
  verificationCreditsGranted: boolean;
  signupCampaign: string | null;
  teams: string[];
  generatedContentPublicMode: boolean;
  accessToken: string;
  roles?: string[];
  cmsId?: number | null;
  deletedAt?: Date | null;
  stripeId?: string | null;
  impactClickId?: string | null;
  adClickId?: string | null;
  ip?: string | null;
  fraudster?: boolean;
  extraProjects?: number;
  signupMedium?: string | null;
  signupSource?: string | null;
  teamId?: string | null;
  signupUrl?: string | null;
} & AccountRole;
