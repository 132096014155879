import React from 'react';

import { Layout, Avatar, Typography } from 'antd';

import { Flex } from 'features/ui/Flex';
import { Account } from 'types/user';
import { buildImageProxyUrl } from 'utils/images';

import styles from './Header.module.css';

interface HeaderProps {
  account?: Account;
  backgroundColor?: string;
}

export const Header: React.FC<HeaderProps> = ({
  account,
  backgroundColor = '#fff',
}) => {
  return (
    <Layout.Header
      className={styles.header}
      style={{ padding: '1rem', backgroundColor }}
    >
      {account && (
        <Flex align="center" gap="small">
          <Typography.Text>{account.name}</Typography.Text>
          <Avatar
            size="large"
            src={
              account.profileImage
                ? buildImageProxyUrl(`api/${account.profileImage}`, {
                    width: 48,
                    height: 48,
                    scale: 2,
                  })
                : null
            }
          >
            {account.name?.[0]}
          </Avatar>
        </Flex>
      )}
    </Layout.Header>
  );
};
